
import {
  IonList,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonItemGroup,
  IonItemDivider,
  IonCard,
  IonCardSubtitle,
  IonGrid,
  IonRow,
  IonCol,
  IonCardTitle,
  IonLoading,
  IonButton,
  IonCardHeader,
  IonLabel,
  IonItem,
} from '@ionic/vue';
import {
  calendarOutline,
  timeOutline,
  analyticsOutline,
  mapOutline,
  trailSignOutline,
  createOutline
} from 'ionicons/icons';
import BaseLayout from "@/components/BaseLayout.vue";
import MemberItem from "@/components/item/MemberItem.vue";
import DetailCard from "@/components/card/DetailCard.vue";
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import ImageHelper from "@/helpers/ImageHelper";

const __default__ = {
  name: 'Activity',
  components: {
    BaseLayout,
    IonList,
    IonListHeader,
    MemberItem,
    IonSegment,
    IonSegmentButton,
    IonItemDivider,
    IonItemGroup,
    IonCard,
    IonCardSubtitle,
    IonGrid,
    IonRow,
    IonCol,
    DetailCard,
    IonCardTitle,
    IonLoading,
    IonButton,
    IonCardHeader,
    IonLabel,
    IonItem
  },
  created() {
    this.loadActivity();
  },
  setup() {
    return {
      calendarOutline,
      timeOutline,
      analyticsOutline,
      mapOutline,
      trailSignOutline,
      createOutline,
    }
  },
  methods: {
    segmentChanged(ev: CustomEvent) {
      this.active = ev.detail.value;
    },
    loadActivity() {
      return this.$store.dispatch('activity/activity', this.$route.params.id);
    },
  },
  computed: {
    time() {
      return dayjs(this.activity.zonedDateTime).format('hh:mm A');
    },
    signup() {
      return {going: this.activity.joined, action: 'activity'};
    },
    bgImg() {
      if (this.activity.details)
        return ImageHelper.backgroundImage('activity', this.activity.details.imageUrl);
      return '';
    },

    settings() {
      const settings = {
        type: 'activity',
        canEdit: false
      }
      if (this.hasGroups) {
        this.groups.forEach(group => {
          if (this.activity.details) {
            if (group.id == this.activity.details.group.id) {
              if (group.groupRole === 'ADMIN') {
                settings.canEdit = true;
              }
            }
          }
        });
      }
      return settings;
    },
    ...mapGetters({
      activity: 'activity/activity',
      groups: 'groups',
      hasGroups: 'hasGroups',
      loading: 'activity/loading'
    }),
  },
  data() {
    return {
      settingsOpen: false,
      active: 'details',
      error: false
    }
  }
}


import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "9d31c7aa": (_ctx.bgImg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__