import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { color: "light" }, {
    default: _withCtx(() => [
      ($props.icon)
        ? (_openBlock(), _createBlock(_component_ion_card_header, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: $props.icon }, null, 8, ["icon"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          ($props.detail)
            ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.detail), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}