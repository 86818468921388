
import {  IonCard,IonText, IonCardContent, IonCardHeader, IonIcon } from "@ionic/vue";

export default {
  name: 'DetailCard',
  props:{
    'detail': String,
    'icon': String,
  },
  components:{
    IonCard,
    IonText,
    IonCardContent,
    IonCardHeader,
    IonIcon
  },
  setup(){
    return {

    }
  },
}

